import React from 'react';
import ReactDOM from 'react-dom'
import {QueryClient, QueryClientProvider} from 'react-query';

//components
import NavBar from "./Components/NavBar/NavBar";
import Copyright from "./Components/Copyright";
import Grid1 from "./Components/Grid1";


export type Props = {
    item: clientProps;
};

export type clientProps = {
    index: number,
    isOnline: boolean,
    title: string,
    clientId: string,
}

const client = new QueryClient();

function App() {
    return (
        <>
            <NavBar/>
            <Grid1/>
            <Copyright/>
        </>
    );
}

ReactDOM.render(
    <QueryClientProvider client={client} contextSharing={true}>
        <App/>
    </QueryClientProvider>,
    document.getElementById('root')
);