import * as React from 'react';
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import classes from "./nav.module.css"
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

function NavBar() {
    return (
        <div className={classes.NavBar}>
            <CssBaseline />
            <AppBar
                style={{ background: '#263238' }}
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >

                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color='#eceff1' noWrap sx={{ flexGrow: 1 }}>
                        Remotekraft
                    </Typography>
                    <Typography className={classes.firmenname}  variant="h6" color='#eceff1' noWrap sx={{ flexGrow: 1 }}>
                       Büro Holm Berbig
                    </Typography>
            <Navigation/>
            <MobileNavigation/>

                </Toolbar>
            </AppBar>
        </div>
);
}

export default NavBar;

