import React from "react";
import {useQuery} from "react-query";
import {Container, Grid} from "@mui/material";
import CardComponent from "./CardComponent";
import LinearProgress from '@material-ui/core/LinearProgress';
import {clientProps} from "../index";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    gridContainer: {
        paddingTop: "70px",
        paddingLeft: "70px",
        paddingRight: "70px",
        justifyItems: "center",
        justifyTracks: "center",
    }
})
const baseURL = process.env.REACT_APP_BASE_URL ?? '';
const getNotes = async (): Promise<clientProps[]> => (
    await fetch(baseURL + "/api/clients")).json();


const Grid1 = () => {
    const gridstyle = useStyles();
    const {data, isLoading, error} = useQuery<clientProps[]>('item', getNotes);
    console.log(data);

    if (isLoading) return <LinearProgress/>;
    if (error) return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h1>Es ist ein
        Fehler aufgetreten.</h1></div>;

    return (
        <Container>
            <Grid container
                  alignItems="center"
                  justifyContent="center"
                  spacing={20}
                  className={gridstyle.gridContainer}>
                {data?.map(item => (
                    <Grid item key={item.index} lg={4}>
                        <CardComponent item={item}/>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Grid1;