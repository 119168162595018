import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

function CopyrightContent(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" marginTop={"20px"} {...props}>
            {'Copyright © '}
            <Link color="inherit" href="">
                Remotekraft v1.0 by Kraftsoft
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Copyright() {
    return <CopyrightContent/>;
}