import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {RiComputerLine} from "react-icons/ri";
import Link from "@mui/material/Link"
import {Props} from "../index";


const OnlineCard: React.FC<Props> = ({ item}) => (
    <Link
        href={"api/rdp/"+item.clientId}
        target={"_blank"}
       sx={{
           textDecoration:"none"
       }} >
            <Card
            style={{
                minWidth:"250px",
                display:"inline-block"
            }}
            >
                <RiComputerLine
                    style={{
                        backgroundColor:"lightgreen",
                        height:250,
                       width:"auto",
                        color:"darkgrey"
                    }}
                size="300px"
                />

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" >
                        {item.title}
                    </Typography>
                </CardContent>
            </Card>
    </Link>
        );

const OfflineCard : React.FC<Props> = ({ item}) => (

    <Link
        sx={{textDecoration:"none"}}
        href={"/"}
        target={"_blank"}
    >
            <Card sx={{
                minWidth:"250px",
                display:"inline-block"
            }}>
                <RiComputerLine
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor:"lightgray",
                        height:250,
                        width:"auto",
                        color:"darkgrey"
                    }}
                    size="300px"
                    />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {item.title}
                    </Typography>
                </CardContent>
            </Card>
    </Link>
);

const CardComponent : React.FC<Props> = ({ item}) => {
    return item.isOnline ? <OnlineCard item={item} /> : <OfflineCard item={item}/>
    }

export default CardComponent;